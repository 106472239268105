import { IEventTracker, IEventTrackerStrategy } from './event-tracker.types'

export class EventTracker<TData, TInit> implements IEventTracker<TData, TInit> {
  constructor(private readonly strategy: IEventTrackerStrategy<TData, TInit>) {}

  track = (eventData: TData): void => {
    this.strategy.execute(eventData)
  }

  init = (initData: TInit): void => {
    this.strategy.init(initData)
  }
}
