import { EventTrackerBuilder, IEventTrackerStrategy } from '../event-tracker'
import { CustomWindow, GtmEvent } from './gtm-tracker.types'

class GoogleTagManager implements IEventTrackerStrategy<GtmEvent, string> {
  execute = (params: GtmEvent) => {
    const win: CustomWindow = window

    if (Array.isArray(win.dataLayer)) {
      win.dataLayer.push(params)
    }
  }

  init = (userid: string) => {
    this.execute({ userid })
  }
}

export const strategy = new GoogleTagManager()
export const GtmTracker = EventTrackerBuilder.strategy(strategy).build()
