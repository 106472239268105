import { EventTracker } from './event-tracker'
import { IEventTracker, IEventTrackerStrategy } from './event-tracker.types'

export class EventTrackerBuilder<TData, TInit> {
  constructor(private readonly strategy: IEventTrackerStrategy<TData, TInit>) {}

  static strategy<TEventData, TInitData>(
    strategy: IEventTrackerStrategy<TEventData, TInitData>,
  ): EventTrackerBuilder<TEventData, TInitData> {
    return new EventTrackerBuilder<TEventData, TInitData>(strategy)
  }

  public build(): IEventTracker<TData, TInit> {
    return new EventTracker<TData, TInit>(this.strategy)
  }
}
